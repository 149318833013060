import React from 'react';

const variants = {
    spinner: () => (
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600" />
    ),
    pulse: () => (
        <div className="animate-pulse flex space-x-4">
            <div className="rounded-full bg-blue-400 h-12 w-12"></div>
            <div className="flex-1 space-y-4 py-1">
                <div className="h-4 bg-blue-400 rounded-sm w-3/4"></div>
                <div className="space-y-2">
                    <div className="h-4 bg-blue-400 rounded-sm"></div>
                    <div className="h-4 bg-blue-400 rounded-sm w-5/6"></div>
                </div>
            </div>
        </div>
    ),
    dots: () => (
        <div className="flex space-x-2">
            {[...Array(3)].map((_, i) => (
                <div
                    key={i}
                    className="h-2 w-2 bg-blue-600 rounded-full animate-bounce"
                    style={{ animationDelay: `${i * 0.2}s` }}
                />
            ))}
        </div>
    ),
    skeleton: ({ lines = 3, className = '' }) => (
        <div className={`space-y-4 ${className}`}>
            {[...Array(lines)].map((_, i) => (
                <div
                    key={i}
                    className="h-4 bg-gray-200 rounded-sm animate-pulse"
                    style={{ width: `${Math.random() * 50 + 50}%` }}
                />
            ))}
        </div>
    ),
    card: () => (
        <div className="border border-gray-200 rounded-lg p-4 max-w-sm w-full">
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 bg-gray-200 rounded-sm w-3/4"></div>
                    <div className="space-y-2">
                        <div className="h-4 bg-gray-200 rounded-sm"></div>
                        <div className="h-4 bg-gray-200 rounded-sm w-5/6"></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const Loading = ({ 
    variant = 'spinner',
    text = 'Loading...',
    fullScreen = false,
    overlay = false,
    lines,
    className = ''
}) => {
    const LoadingVariant = variants[variant] || variants.spinner;

    const content = (
        <div className={`flex flex-col items-center justify-center space-y-4 ${className}`}>
            <LoadingVariant lines={lines} />
            {text && <p className="text-gray-600 font-medium">{text}</p>}
        </div>
    );

    if (fullScreen) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
                {content}
            </div>
        );
    }

    if (overlay) {
        return (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                {content}
            </div>
        );
    }

    return content;
};

// Specialized loading components for common use cases
export const PageLoader = () => (
    <Loading
        variant="spinner"
        fullScreen
        text="Loading page..."
    />
);

export const CardLoader = () => (
    <Loading
        variant="card"
        className="w-full"
    />
);

export const TableLoader = () => (
    <Loading
        variant="skeleton"
        lines={5}
        className="w-full"
    />
);

export const ButtonLoader = () => (
    <Loading
        variant="dots"
        text=""
        className="h-5"
    />
);

export default Loading;
