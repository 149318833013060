import React, { Suspense, useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import ErrorBoundary from './components/common/ErrorBoundary';
import { PageLoader } from './components/common/Loading';

// Lazy load pages for better performance
const Home = React.lazy(() => import('./pages/Home'));
const Contact = React.lazy(() => import('./pages/Contact'));
const DemoProduct = React.lazy(() => import('./pages/DemoProduct'));
const Blog = React.lazy(() => import('./pages/Blog'));
const BlogPost = React.lazy(() => import('./pages/BlogPost'));

// Transition wrapper component with improved animation
const PageTransition = ({ children }) => {
  const location = useLocation();
  return (
    <div
      key={location.pathname}
      className="page-transition"
      style={{
        animation: 'fadeThrough 0.5s ease-in-out',
        minHeight: '100vh'
      }}
    >
      {children}
    </div>
  );
};

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    // Initialize AOS immediately and on load
    aos_init();
    window.addEventListener('load', aos_init);

    return () => window.removeEventListener('load', aos_init);
  }, []);

  useDocTitle("SamSol | Samaritan Solutions - 'IT' Doesn't have to be hard!");

  return (
    <ErrorBoundary>
      <Router>
        <ScrollToTop>
          <PageTransition>
            <Suspense fallback={<PageLoader />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/get-demo" element={<DemoProduct />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:postId" element={<BlogPost />} />
              </Routes>
            </Suspense>
          </PageTransition>
        </ScrollToTop>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
