import { useEffect } from 'react';

// Custom hook for document title
export const useDocTitle = title => {
    useEffect(() => {
        document.title = title;
    }, [title]);
};

// Custom hook for intersection observer animations
export const useInView = (ref, options = {}, callback) => {
    useEffect(() => {
        if (!ref.current) return;

        // Store the current value in a variable to avoid stale refs
        const element = ref.current;

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                if (callback) {
                    callback(entry);
                } else {
                    // Default animation
                    entry.target.classList.add('animate-in');
                }
                // Unobserve after animation
                observer.unobserve(entry.target);
            }
        }, {
            threshold: 0.1,
            ...options
        });

        // Only observe if element exists
        if (element) {
            observer.observe(element);
        }

        return () => {
            // Use the stored element reference in cleanup
            if (element) {
                observer.unobserve(element);
            }
            observer.disconnect();
        };
    }, [ref, options, callback]);
};
