import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Report } from 'notiflix/build/notiflix-report-aio';
import { Loading } from 'notiflix/build/notiflix-loading-aio';

// Configure default notification settings
Notify.init({
    position: 'right-top',
    distance: '15px',
    borderRadius: '8px',
    timeout: 3000,
    showOnlyTheLastOne: true,
    cssAnimationStyle: 'from-right',
});

// Configure default report settings
Report.init({
    backgroundColor: '#f8fafc',
    success: {
        svgColor: '#059669',
        titleColor: '#059669',
        buttonBackground: '#059669',
    },
    failure: {
        svgColor: '#dc2626',
        titleColor: '#dc2626',
        buttonBackground: '#dc2626',
    },
    warning: {
        svgColor: '#d97706',
        titleColor: '#d97706',
        buttonBackground: '#d97706',
    },
});

// Configure default loading settings
Loading.init({
    svgColor: '#2563eb',
    backgroundColor: 'rgba(0,0,0,0.8)',
    messageFontSize: '1rem',
});

export const notify = {
    success: (message) => {
        Notify.success(message);
    },
    error: (message) => {
        Notify.failure(message);
    },
    warning: (message) => {
        Notify.warning(message);
    },
    info: (message) => {
        Notify.info(message);
    },
};

export const report = {
    success: (title, message, buttonText = 'OK') => {
        Report.success(title, message, buttonText);
    },
    error: (title, message, buttonText = 'OK') => {
        Report.failure(title, message, buttonText);
    },
    warning: (title, message, buttonText = 'OK') => {
        Report.warning(title, message, buttonText);
    },
};

export const loading = {
    start: (message = 'Loading...') => {
        Loading.standard(message);
    },
    success: (message = 'Success!', duration = 1000) => {
        Loading.remove();
        Notify.success(message, { timeout: duration });
    },
    error: (message = 'Error occurred', duration = 2000) => {
        Loading.remove();
        Notify.failure(message, { timeout: duration });
    },
    stop: () => {
        Loading.remove();
    },
};

// Custom hooks for common notification patterns
export const useNotification = () => {
    return {
        notify,
        report,
        loading,
        showFormSuccess: (message = 'Form submitted successfully!') => {
            notify.success(message);
        },
        showFormError: (error) => {
            const message = error?.message || 'An error occurred. Please try again.';
            notify.error(message);
        },
        showLoadingSuccess: (message = 'Operation completed successfully!') => {
            loading.success(message);
        },
        showLoadingError: (error) => {
            const message = error?.message || 'An error occurred. Please try again.';
            loading.error(message);
        },
    };
};
